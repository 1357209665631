import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/formFissuresMaison"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Koudepouce</title>
        <meta name="description" content="Koudepouce simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Trouvez un pro de l’immobilier près de chez vous
              </span>
            </h1>
            
            
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Architecte, constructeur, bureau d’étude géotechnique, artisan, expert bâtiment, société de reprise de fondations… Koudepouce est le site expert de la mise en relation avec des entreprises du bâtiment partout en France. Nous vous répondrons dans les plus brefs délais.
              </p>
            </div>

          </div>
        </div>

        <Form />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}